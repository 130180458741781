import { useCallback } from 'react';
import { useParams } from 'next/navigation';
import { datadogLogs } from '@datadog/browser-logs';
import { Address } from 'shared/types/account/Address';
import { Order } from 'shared/types/cart/Order';
import { mutate } from 'swr';
import * as uuid from 'uuid';
import { getCookie } from 'helpers/utils/cookies';
import { fetchWithIdToken } from 'helpers/utils/fetch';
import { getLocalizationInfo } from 'project.config';
import { sdk } from 'sdk';
import { useAccount, useCart } from 'frontastic';

const usePurchase = () => {
  const localeCartMapper = {
    'en-us': 'en',
    'de-de': 'de',
    'es-es': 'es',
    'fr-fr': 'fr',
    'it-it': 'it',
    'nl-nl': 'nl',
    'ar-sa': 'ar',
    'pt-br': 'pt-BR',
    'th-th': 'th',
    'zh-cn': 'zh-CN',
    'zh-hk': 'zh-HK',
    'ja-jp': 'ja',
    'ko-kr': 'ko',
  } as Record<string, string>;
  const { transaction, data } = useCart();
  const { account, updateAddress, getProjectConfig, defaultShippingAddress, defaultBillingAddress } = useAccount();
  const { locale } = useParams();
  const translationLocale = getLocalizationInfo(locale).translationLocale;
  const language = translationLocale in localeCartMapper ? localeCartMapper[translationLocale] : 'en';

  const createStripeCheckoutSession = useCallback(async () => {
    const payload = {
      locale: language,
    };
    await sdk.callAction({
      actionName: 'cart/setLocale',
      payload,
    });
    const stripeUrl = await getProjectConfig('EXTENSION_STRIPE_BASE_URL');
    const res = await fetchWithIdToken(
      `${stripeUrl.setting}/create-checkout-session`,
      'POST',
      JSON.stringify({ cartId: data?.cartId }),
    );
    if (res.ok) {
      const data = await res.json();
      if (data.error && data.error.statusCode === 400) {
        return null;
      }
      return data?.session?.url;
    }

    return null;
  }, [data, transaction]);

  const updateUserCartAddress = useCallback(
    async (billingAddress: Address, affiliateId: string, storeNumber: string, country: any) => {
      const address = {
        firstName: billingAddress.firstName,
        lastName: billingAddress.lastName,
        streetName: billingAddress.streetName,
        postalCode: billingAddress.postalCode,
        city: billingAddress.city,
        country: billingAddress.country,
        state: billingAddress.state,
        additionalStreetInfo: billingAddress.additionalStreetInfo,
        phone: billingAddress.phone,
      } as Address;
      const additionalAddressInfo = JSON.stringify({
        ...address,
        email: account?.email,
        taxAddress: {
          countryName: country?.englishName,
          countryId: country?.id,
        },
      });

      // update cart's custom type and adress
      const impactCookie = getCookie('PADI.Impact.irclickid') || '';
      const billing: Address = {
        ...address,
        department: storeNumber,
        additionalAddressInfo,
        externalId: affiliateId,
        company: impactCookie,
      };
      const payload = {
        email: account?.email,
        affiliateId,
        storeNumber,
        billing,
        shipping: {
          state: address.state,
          country: address.country,
          additionalAddressInfo,
          // ...billingAddress,
        },
      };
      await sdk.callAction({
        actionName: 'cart/updateCartWithAddress',
        payload,
      });

      await mutate('/action/cart/getCart');

      return billing;
    },
    [],
  );

  const createPaymentForPaypal = useCallback(async (saleTransactionId: string, payerId: string) => {
    await sdk.callAction({
      actionName: 'cart/createPaymentForPaypal',
      payload: {
        key: uuid.v4(),
        saleTransactionId,
        payerId,
      },
    });
  }, []);

  const createOrderForPaypal = useCallback(
    async (
      affiliateId: string,
      storeNumber: string,
      country: any,
      billingAddress?: Address,
      shippingAddress?: Address,
    ) => {
      const orderNumber = data?.cartId;
      const address = billingAddress || {};
      if (country && country.id) {
        // TODO fix this to address.additionalAddressInfo.taxAddress
        address.additionalAddressInfo = JSON.stringify({
          countryName: country.englishName,
          countryId: country.id,
        });
      }
      const res = await sdk.callAction({
        actionName: 'cart/createOrderForPaypal',
        payload: {
          billingAddress: address,
          orderNumber,
          affiliateId,
          storeNumber,
          shippingAddress,
        },
      });
      if (res.isError) {
        datadogLogs.logger.error('Checkout page - After creating Paypal order', {
          data: {
            email: account?.email,
            cart: data,
            errorMessage: res.error?.message,
            billingAddress,
            shippingAddress,
          },
        });
        return false;
      } else {
        if ((res.data as Order).orderId) {
          datadogLogs.logger.info('Checkout page - After creating Paypal order', {
            data: {
              email: account?.email,
              cart: data,
              billingAddress,
              shippingAddress,
            },
          });
        } else {
          datadogLogs.logger.error('Checkout page - After creating Paypal order', {
            data: {
              email: account?.email,
              cart: data,
              errorMessage: res.data,
              billingAddress,
              shippingAddress,
            },
          });
        }
        return true;
      }
    },
    [data],
  );

  const getOrderByOrderNumber = useCallback(async (orderNumber: string) => {
    const res = await sdk.callAction({
      actionName: 'cart/getOrderByOrderNumber',
      payload: {
        orderNumber,
      },
    });
    return (res.isError ? {} : res.data) as any;
  }, []);

  const getPaymentDetail = useCallback(async (paymentNumber: string) => {
    const res = await sdk.callAction({
      actionName: 'padi-ct-payment/getPaymentDetail',
      payload: {
        paymentNumber,
      },
    });
    return (res.isError ? {} : (res.data as any)?.body) as any;
  }, []);

  return {
    createStripeCheckoutSession,
    updateUserCartAddress,
    createPaymentForPaypal,
    createOrderForPaypal,
    getOrderByOrderNumber,
    getPaymentDetail,
  };
};

export default usePurchase;
