import { useRef, useCallback } from 'react';
import { datadogLogs } from '@datadog/browser-logs';
import { Cart, LineItem } from 'shared/types/cart';
import { Order } from 'shared/types/cart/Order';
import {
  PURCHASE,
  ADD_PAYMENT_INFO,
  EVENT_ITEM_CATEGORY_BUNDLES,
  EVENT_ITEM_CATEGORY_COURSES,
  EVENT_ITEM_CATEGORY_CERT_CARDS,
  EVENT_ITEM_CATEGORY_CLUB,
  EVENT_ITEM_CATEGORY_DONATION,
  DEFAULT_CURRENCY,
  PADI_CHECKOUT_NAME,
  PADI_BRAND,
} from 'helpers/constants/events';

// Helper type for the structure of a tracking item
interface TrackingItem {
  item_id: string;
  item_name: string;
  item_brand: string;
  item_category: string;
  item_category2: string;
  item_category3: string;
  item_category4: string;
  item_category5: string;
  bundle_name: string;
  is_bundle: boolean;
  price: number;
  discount: number;
  quantity: number;
}

// Safely fetch attribute values with a default value.
const getAttributeValue = (attr: any, defaultValue = '') => {
  if (Array.isArray(attr)) return attr[0]?.label ?? defaultValue;
  else return attr?.label ?? defaultValue;
};

// Create a tracking item from a line item
const createTrackingItem = (lineItem: LineItem): TrackingItem => {
  // Calculate total discount by summing up all individual discounts
  let totalDiscount = 0;
  if (lineItem.discounts) {
    totalDiscount = lineItem.discounts.reduce((acc, discount) => {
      return acc + (discount.discountedAmount?.centAmount ? discount.discountedAmount.centAmount / 100 : 0);
    }, 0);
  }

  const bundleProducts = lineItem.variant?.attributes?.['products'];
  const isBundle = Array.isArray(bundleProducts) && bundleProducts.length > 1;
  const bundleSku = isBundle ? lineItem.variant?.sku ?? '' : '';
  const bundleName = isBundle ? lineItem?.name ?? '' : '';
  const sku = lineItem.variant?.sku;
  const price = lineItem.price?.centAmount ? lineItem.price?.centAmount / 100 : 0;
  const productType = lineItem.productType?.obj?.key;

  const data = {
    item_id: lineItem.productId ?? '',
    item_name: lineItem.name ?? '',
    item_brand: PADI_BRAND,
    item_category: '',
    item_category2: '',
    item_category3: '',
    item_category4: '',
    item_category5: '',
    bundle_name: '',
    is_bundle: false,
    discount: totalDiscount,
    price: price,
    quantity: lineItem.count ?? 1,
  };

  switch (productType) {
    case 'bundle-parent':
      data.item_category = EVENT_ITEM_CATEGORY_BUNDLES;
      data.item_category5 = bundleSku;
      data.bundle_name = bundleName;
      data.is_bundle = isBundle;
      break;
    case 'Course':
      data.item_category = EVENT_ITEM_CATEGORY_COURSES;
      data.item_category2 = getAttributeValue(lineItem.variant?.attributes?.['experience-level']);
      data.item_category3 = getAttributeValue(lineItem.variant?.attributes?.['course-type']);
      data.item_category4 = getAttributeValue(lineItem.variant?.attributes?.['discipline']);
      break;
    case 'certification-card':
      data.item_category = EVENT_ITEM_CATEGORY_CERT_CARDS;
      break;
    case 'donation':
      data.item_category = EVENT_ITEM_CATEGORY_DONATION;
      break;
    case 'subscription':
      if (sku === 'MPC40955-1B2C') data.item_category = EVENT_ITEM_CATEGORY_CLUB;
      break;
    default:
      break;
  }

  return data;
};

export const useTrackPage = () => {
  const lastCartId = useRef<string | undefined>(undefined);
  const triggerDataDog = (message: string, data: any) => {
    datadogLogs.logger.info(message, { data });
  };

  const trackPage = useCallback(async (order: Order | null) => {
    if (order && order.cartId !== lastCartId.current) {
      const items = order.lineItems?.map((item) => createTrackingItem(item)) ?? [];
      const paymentMethod = order.payments?.[0]?.paymentMethod ?? '';
      const totalValue = order.sum?.centAmount ? order.sum.centAmount / 100 : 0;
      const orderId = order.orderId ?? order.cartId ?? '';
      const shippingTotal = order.shippingInfo?.price?.centAmount ? order.shippingInfo.price.centAmount / 100 : 0;
      const couponCode = order.discountCodes?.[0]?.code ?? '';

      // Calculate the tax amount using taxPortions
      let taxAmount = 0;
      if (order.taxed?.taxPortions) {
        taxAmount = order.taxed.taxPortions.reduce((acc, taxPortion) => {
          return acc + (taxPortion.amount?.centAmount ? taxPortion.amount.centAmount / 100 : 0);
        }, 0);
      }

      const payload = {
        padi_checkout_name: PADI_CHECKOUT_NAME,
        currency: order.sum?.currencyCode ?? DEFAULT_CURRENCY,
        payment_type: paymentMethod,
        value: totalValue,
        transaction_id: orderId,
        tax: taxAmount,
        shipping: shippingTotal,
        coupon: couponCode,
        items: items,
      };

      triggerDataDog('purchase event', payload);
      gtag('event', PURCHASE, payload);

      lastCartId.current = order.cartId;
    }
  }, []);

  return { trackPage };
};

export const useTrackClick = () => {
  const lastCartId = useRef<string | undefined>(undefined);

  const trackClick = useCallback(async (cart: Cart | null) => {
    if (cart && cart.cartId !== lastCartId.current) {
      const items = cart.lineItems?.map((item) => createTrackingItem(item)) ?? [];
      const totalValue = cart.sum?.centAmount ? cart.sum.centAmount / 100 : 0;

      gtag('event', ADD_PAYMENT_INFO, {
        padi_checkout_name: PADI_CHECKOUT_NAME,
        currency: cart.sum?.currencyCode ?? DEFAULT_CURRENCY,
        value: totalValue,
        items: items,
      });

      lastCartId.current = cart.cartId;
    }
  }, []);

  return { trackClick };
};

export default useTrackPage;
